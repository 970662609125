import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { tts_config } from 'src/environments/tts_config';

@Injectable({
  providedIn: 'root'
})
export class PagesService {

  constructor(private http: HttpClient) { }

  GetPageDetail(slug:string)
  {
    let url=tts_config.APIURL+"/page?slug="+slug+"";
    return this.http.get(url,{headers: { 'Content-Type': 'application/json'}});
  }
}
