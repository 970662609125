import { DatePipe, DecimalPipe } from '@angular/common';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { tts_config } from 'src/environments/tts_config';

@Injectable({
  providedIn: 'root'
})
export class CabService {

  constructor(private http: HttpClient,public datepipe: DatePipe,private decimalPipe: DecimalPipe) { }

  autocomplete(type:any,searchtext:string)
  {
     // Initialize Params Object
     let params = new HttpParams();
     params = params.append('type', type.toString());
     params = params.append('searchstring', searchtext.toString());
     let url=tts_config.APIURL+'/car/city_list';
     return this.http.get(url, { params: params});
  }

  GetResult(data:any)
  {
    //return this.http.get('assets/cabresponse.json');
    let url=tts_config.APIURL+'/car/search';
    return this.http.post(url, data, {headers: { 'Content-Type': 'application/json'}}); 
  }

  GetDetails(data:any)
  {
    let url=tts_config.APIURL+'/car/cabdetail';
    return this.http.post(url, data, {headers: { 'Content-Type': 'application/json'}}); 
  }

  Applycoupon(data:any)
  {
   let url=tts_config.APIURL+'/car/getcoupandetail';
   return this.http.post(url, data, {headers: { 'Content-Type': 'application/json'}});
  }

  SavePaxdata(data: any)
  {
    let url=tts_config.APIURL+'/car/savepaxinfo';
    return this.http.post(url, data, {headers: { 'Content-Type': 'application/json'}});
  }

  Book(data:any)
  {
    let url=tts_config.APIURL+'/car/book';
    return this.http.post(url, data, {headers: { 'Content-Type': 'application/json'}});
  }

  GetTicketDetail(data: any)
  {
    let url=tts_config.APIURL+'/car/getCarInfo';
    return this.http.get(url, { params: data});
  }

  Viewticket(data: any)
  {
    let url=tts_config.APIURL+'/car/pwapdf';
    return this.http.get(url, { params: data});
  }

  CancalRequest(data:any)
  {
    let url=tts_config.APIURL+'/car/CancelRequest';
    return this.http.post(url, data, {headers: { 'Content-Type': 'application/json'}});
  }

  DefaultDateFormat(date)
  {
    let myDate = new Date(date);
    return this.datepipe.transform(myDate, 'E, d MMM yy');
  }
  GetCurrentDate(day :number)
  {
    let myDate = new Date(new Date().getTime()+(day*24*60*60*1000));
    return myDate;
  }

  Calculatedatediff(date1,date2)
  {
    let diffc;
    let days;
    let ndate1=new Date(date1);
    let ndate2=new Date(date2);
    diffc = ndate1.getTime() - ndate2.getTime();
    days = Math.round(Math.abs(diffc/(1000*60*60*24)));
    return days;
  }

  AddDayDefaultDate(date : any , day :number)
  {
    let myDate = new Date(new Date(date).getTime()+(day*24*60*60*1000));
     return this.datepipe.transform(myDate, 'E, d MMM yy');
  }

  APPCalendorDate(date)
  {
    let myDate = new Date(date);
    let d=this.datepipe.transform(myDate, 'd');
    let m=this.datepipe.transform(myDate, 'MMM');
    let y=this.datepipe.transform(myDate, 'yy');
    let w=this.datepipe.transform(myDate, 'EEEE');
    let fulldate=this.datepipe.transform(myDate, 'E, d MMM yy');

    let obj={};
    obj['D']=d;
    obj['M']=m;
    obj['Y']=y;
    obj['W']=w;
    obj['FullDate']=fulldate;
    return obj;
  }
  DateFormat1(date)
  {
    let myDate = new Date(date);
    return this.datepipe.transform(myDate, 'yyyy-MM-dd');
  }

  transformDecimal(num) {
    return this.decimalPipe.transform(num, '1.0-0');
   }

}
