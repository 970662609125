import { DatePipe } from '@angular/common';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { tts_config } from 'src/environments/tts_config';

@Injectable({
  providedIn: 'root'
})
export class ActivityService {

  constructor(private http: HttpClient,public datepipe: DatePipe) { }

  autocomplete(searchtext:string)
  {
     // Initialize Params Object
     let params = new HttpParams();
     params = params.append('term', searchtext.toString());
     let url=tts_config.APIURL+'/activity/city_list';
     return this.http.get(url, { params: params});
  }

  GetActivity()
  {
    let url=tts_config.APIURL+'/activity/activity_home';
    return this.http.get(url);
  }

  SearchActivity(data:any)
  {
    let url=tts_config.APIURL+'/activity/search';
    return this.http.post(url, data, {headers: { 'Content-Type': 'application/json'}});
  }

  GetDetail(data:any)
  {
    let url=tts_config.APIURL+'/activity/product_details';
    return this.http.post(url, data, {headers: { 'Content-Type': 'application/json'}});
  }

  GetTripDetail(data:any)
  {
    let url=tts_config.APIURL+'/activity/trip_list';
    return this.http.post(url, data, {headers: { 'Content-Type': 'application/json'}});
  }

  BlockDetail(data:any)
  {
    let url=tts_config.APIURL+'/activity/block_trip';
    return this.http.post(url, data, {headers: { 'Content-Type': 'application/json'}});
  }

  SavePaxdata(data: any)
  {
    let url=tts_config.APIURL+'/activity/savepaxdata';
    return this.http.post(url, data, {headers: { 'Content-Type': 'application/json'}});
  }
  Book(data:any)
  {
    let url=tts_config.APIURL+'/activity/book';
    return this.http.post(url, data, {headers: { 'Content-Type': 'application/json'}});
  }
  
  GetTicketDetail(data:any)
  {
    let url=tts_config.APIURL+'/activity/getactivityInfo';
    return this.http.get(url, { params: data});
  }

  Viewticket(data: any)
  {
    let url=tts_config.APIURL+'/activity/pwapdf';
    return this.http.get(url, { params: data});
  }

  CancalRequest(data:any)
  {
    let url=tts_config.APIURL+'/activity/activitycancellation';
    return this.http.post(url, data, {headers: { 'Content-Type': 'application/json'}});
  }

  APPCalendorDate(date)
  {
    let myDate = new Date(date);
    let d=this.datepipe.transform(myDate, 'd');
    let m=this.datepipe.transform(myDate, 'MMM');
    let y=this.datepipe.transform(myDate, 'yy');
    let w=this.datepipe.transform(myDate, 'EEEE');
    let fulldate=this.datepipe.transform(myDate, 'E, d MMM yy');

    let obj={};
    obj['D']=d;
    obj['M']=m;
    obj['Y']=y;
    obj['W']=w;
    obj['FullDate']=fulldate;
    return obj;
  }

  Calculatedatediff(date1,date2)
  {
    let diffc;
    let days;
    let ndate1=new Date(date1);
    let ndate2=new Date(date2);
    diffc = ndate1.getTime() - ndate2.getTime();
    days = Math.round(Math.abs(diffc/(1000*60*60*24)));
    return days;
  }
  DefaultDateFormat(date)
  {
    let myDate = new Date(date);
    return this.datepipe.transform(myDate, 'E, d MMM yy');
  }
  GetCurrentDate(day :number)
  {
    let myDate = new Date(new Date().getTime()+(day*24*60*60*1000));
    return myDate;
  }

  Applycoupon(data:any)
  {
   let url=tts_config.APIURL+'/activity/getcoupandetail';
   return this.http.post(url, data, {headers: { 'Content-Type': 'application/json'}});
  }

  
}
